import React from "react";
import { Text, Icon, Image } from "@atoms";
import classNames from "classnames";
import { useAppState } from "@state";
import t from "@utils/t";
import { m } from "framer-motion";

const SideScroll = ({ uid, intro, slides }) => {
  const [, dispatch] = useAppState(false);

  return (
    <div className="relative z-20 h-[90vh] max-h-[50rem] w-full pl-4 pr-4 text-white transition duration-300 md:pl-8 md:pr-0">
      <div className="h-full w-full flex-wrap justify-start md:flex md:flex-nowrap md:gap-8">
        <div className="w-full py-0 md:w-3/6 md:py-14">
          <Text className="prose max-w-xl text-xl font-bold uppercase leading-none text-white md:text-2xl">
            {intro.descriptor}
          </Text>
        </div>
        <div className="mt-4 flex w-full py-0 sm:w-1/2 md:mt-0 md:w-2/6 md:py-14">
          <Text className="prose ml-auto pr-8 text-white md:max-w-[20rem] md:pr-0 xl:max-w-[14rem]">
            {intro.copy}
          </Text>
        </div>
        <div className="relative w-full sm:w-1/2 md:w-1/6">
          <m.div
            initial={{ x: "100%", opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            duration={0.2}
            viewport={{ margin: "-10% 0% -50% 0%" }}
            className="relative h-full w-full overflow-hidden bg-black transition duration-700"
          >
            <div className="w-screen">
              <Image image={slides[0].image} fill ixParams={{ fit: "crop" }} />
            </div>
          </m.div>
          <m.div
            initial={{ x: "-1.5rem", opacity: 0 }}
            duration={0.2}
            delay={0.5}
            whileInView={{ x: "1.5rem", opacity: 1 }}
            className="absolute right-8 -bottom-16 flex items-center justify-center gap-3 transition duration-300 ease-out md:right-[100%] md:bottom-8"
          >
            <button
              type="button"
              onClick={() =>
                dispatch({
                  type: "enableSideScroll",
                  id: uid,
                  content: { intro, slides },
                })
              }
              className={classNames(
                "flex animate-pulse items-center justify-center gap-3"
              )}
            >
              <Text variant="sm">{t("Explore")}</Text>
              <div className="h-12 w-12 shrink-0 rounded-full border-2 border-white p-3">
                <Icon name="arrow" fitHeight className="h-full w-full " />
              </div>
            </button>
          </m.div>
        </div>
      </div>
      <div className="pointer-events-none -translate-y-full pb-4">
        <Text variant="h2">{intro.heading}</Text>
      </div>
    </div>
  );
};

export default SideScroll;
